import React from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"
import ResponsiveContainer, {
  ResponsiveFullWidth,
} from "@src/styles/responsive-container"
import { ButtonGroup } from "@src/components/core-button"

const StyledButtonGroup = styled(ButtonGroup)`
  margin-top: 15px;
  @media only screen and (${props => props.theme.screen.small.min}) {
    margin-top: 20px;
  }
`

const CTABlockWhite = css`
  background-color: ${props => props.theme.white};
  color: ${props => props.theme.darkGray};
`
const CTABlockBlue = css`
  background-color: ${props => props.theme.lightBlue};
  color: ${props => props.theme.darkBlue};
`
const CTABlockBlack = css`
  background-color: ${props => props.theme.black};
  color: ${props => props.theme.white};
`

const CTASection = css`
  ${ResponsiveContainer}
  ${ResponsiveFullWidth}
`

const CTA = styled.div`
  padding: 24px 0;
  text-align: center;

  @media only screen and (${props => props.theme.screen.small.min}) {
    padding: 30px 0;
  }

  ${props =>
    props.variant === "white"
      ? CTABlockWhite
      : props.variant === "blue"
      ? CTABlockBlue
      : props.variant === "black"
      ? CTABlockBlack
      : undefined}

  ${props => (props.as === "section" ? CTASection : undefined)}
`
const CTABlock = ({ section, actions, children, className, variant }) => {
  const ctaProps = {
    className,
    variant,
  }
  if (section) {
    ctaProps.as = "section"
  }
  return (
    <CTA {...ctaProps}>
      {children}
      {actions && <StyledButtonGroup>{actions}</StyledButtonGroup>}
    </CTA>
  )
}
CTABlock.propTypes = {
  section: PropTypes.bool,
  actions: PropTypes.node,
  children: PropTypes.node,
  className: PropTypes.string,
  variant: PropTypes.oneOf(["blue", "white", "black"]),
}
export default CTABlock
