export const REASONS_TO_BOOK = [
  {
    image: "/images/social-proof-world.png",
    title: "Your trip. Your way.",
    details:
      "Make lifelong memories with the people you care about most. We specialize in custom & private active travel experiences.",
  },
  {
    image: "/images/social-proof-compass.png",
    title: "Travel made easy.",
    details:
      "Spend your time making memories, not planning them. Enjoy a stress-free vacation and leave the trip logistics to us.",
  },
  {
    image: "/images/social-proof-list.png",
    title: "You matter to us.",
    details:
      "No bots here. A real human is always a click or a call away to ensure you get the adventure of your dreams.",
  },
  {
    image: "/images/social-proof-luggage.png",
    title: "Travel authentically.",
    details:
      "Immerse yourself in the destination with highly-vetted, local tour operators who love to showcase where they live.",
  },
]
