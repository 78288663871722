import React, { useEffect } from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import styled from "styled-components"
import GTM from "@src/services/gtm"
import PrimaryLayout from "@src/layouts/primary"
import PageHelmet, { PageSeoPropTypes } from "@src/components/page-helmet"
import { normalizeTitle } from "@src/utils/page-data"
import HeaderSimple from "@src/components/page-header-simple"
import ResponsiveSection from "@src/components/container-responsive-section"
import CTABlock from "@src/components/cta-block"
import Image, { ImagePropTypes } from "@src/components/core-image"
import HtmlContent, { htmlToJsx } from "@src/components/core-value-html"
import SocialProofReasons from "@src/components/content-social-proof-reasons"
import SocialProofTestimonials from "@src/components/content-social-proof-testimonials"
import SocialProofMediaPartners from "@src/components/content-social-proof-media-partners"
import HighlightTiles from "@src/components/content-highlight-tiles"
import { HighlightTilePropTypes } from "@src/components/content-highlight-tile"
import Accordion from "@src/components/core-accordion"
import Button, { ButtonGroup } from "@src/components/core-button"
import { SITE_DOMAIN } from "@src/utils/constants"

const StyledButtonGroup = styled(ButtonGroup)`
  margin-top: 30px;
`

const StyledDetailsFooter = styled(ResponsiveSection)`
  margin-bottom: 40px;
`
const StyledTestimonials = styled(ResponsiveSection)`
  margin-top: 40px;
  @media only screen and (${props => props.theme.screen.small.min}) {
    margin-bottom: 50px;
  }
`

const LandingPageFeature = styled.div`
  margin: 0 0 20px;
  & > *:first-child {
    margin: 0 0 10px;
  }

  @media only screen and (${props => props.theme.screen.small.min}) {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin: 0 -10px 40px;
    & > * {
      margin: 0 10px;
      flex: 0 1 auto;

      &:first-child {
        margin: 0 10px;
        flex: 0 0 auto;
        width: calc(50% - 20px);
      }
    }
    &:nth-child(2n) {
      flex-direction: row-reverse;
    }
  }
`
const BestTimeBlock = styled.div`
  margin: 0 0 20px;
  @media only screen and (${props => props.theme.screen.small.min}) {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin: 0 -10px 40px;
    & > * {
      margin: 0 10px;
      flex: 0 1 auto;
      &:first-child {
        flex: 0 0 40%;
      }
    }
  }
  @media only screen and (${props => props.theme.screen.medium.min}) {
    & > * {
      &:first-child {
        flex: 0 0 30%;
      }
    }
  }
`

const renderCTAButton = (button, theme, key) => {
  let buttonColor
  switch (theme) {
    case "purple-black":
    case "purple-white":
      buttonColor = "purple"
      break
    case "red-black":
    case "red-white":
      buttonColor = "red"
      break
  }
  return (
    <Button
      key={key}
      name={key}
      to={button.uri}
      color={buttonColor}
      generateDataLayer={() => ({ "highlight-tile": button.text })}
      icon="calendar-dates"
    >
      {button.text}
    </Button>
  )
}
const renderCTA = cta => {
  let ctaColor
  switch (cta.theme) {
    case "purple-black":
    case "red-black":
      ctaColor = "black"
      break
    case "purple-white":
    case "red-white":
      ctaColor = "white"
      break
  }
  return (
    <CTABlock
      section
      variant={ctaColor}
      actions={
        cta.buttons && cta.buttons.length
          ? cta.buttons.map((button, index) =>
              renderCTAButton(button, cta.theme, `${cta.index}-button-${index}`)
            )
          : null
      }
    >
      <h3 className="h4">{cta.text}</h3>
    </CTABlock>
  )
}

const PageTemplate = ({ data }) => {
  const {
    tenAdventures: {
      landingPage: {
        uri: pageUri,
        seo: pageSeo,
        headerImage,
        headerHero,
        ctas,
        content,
        highlightTilesTitle,
        highlightTiles,
        detailsTitle,
        detailsImage,
        detailsAccordion,
        detailsFooter,
        features,
        bestTimeTitle,
        bestTimeBlocks,
        footer,
        footerTilesTitle,
        footerTiles,
      },
    },
  } = data
  GTM.dataLayerPushPageInfo({
    template: "landing-page",
  })
  useEffect(() => {
    GTM.dataLayerPush({
      event: "10a.landing-page.view",
      "landing-page": pageUri.replace(
        /^\/landing-page\/(.+?)\/$/,
        (match, lpSlug) => lpSlug
      ),
    })
  }, [pageUri])
  return (
    <PrimaryLayout>
      <PageHelmet
        title={normalizeTitle(pageSeo.title)}
        meta={{
          canonical: `${SITE_DOMAIN}${pageUri}`,
          description: pageSeo.metaDesc,
          robots: [pageSeo.metaRobotsNofollow, "index"],
          image: pageSeo.seoImage,
          openGraph: {
            type: pageSeo.opengraphType,
            modifiedTime: pageSeo.opengraphModifiedTime,
          },
          twitter: {
            data: [],
          },
        }}
      />
      <HeaderSimple spacer="margin" image={headerImage}>
        <h1>{headerHero.title}</h1>
        {htmlToJsx(
          headerHero.text.replace(/<p.*?>/, "<h2>").replace("</p>", "</h2>")
        )}
        {headerHero.buttons && headerHero.buttons.length ? (
          <StyledButtonGroup>
            {headerHero.buttons.map((button, index) =>
              renderCTAButton(
                button,
                headerHero.theme,
                `lp-header-hero-button-${index}`
              )
            )}
          </StyledButtonGroup>
        ) : null}
      </HeaderSimple>
      <ResponsiveSection html={content} />
      <ResponsiveSection>
        <h2>{highlightTilesTitle}</h2>
        <HighlightTiles
          id="landing-page-highlight-tiles"
          tiles={highlightTiles}
        />
      </ResponsiveSection>
      <SocialProofReasons />
      {ctas && ctas.length > 0
        ? renderCTA({ ...ctas[0], index: "lp-cta-0" })
        : null}
      <ResponsiveSection>
        <h2>{detailsTitle}</h2>
        <Image artDirection {...detailsImage} />
        {detailsAccordion && detailsAccordion.length ? (
          <Accordion
            id="landing-page-details"
            items={detailsAccordion.map(item => ({
              title: item.label,
              icon: item.icon,
              content: item.details,
            }))}
            color="outline"
            toggleIcons="plus-minus"
            solo
            alwaysOpen
          />
        ) : null}
      </ResponsiveSection>
      <StyledDetailsFooter html={detailsFooter} align="center" />
      {ctas && ctas.length > 1
        ? renderCTA({ ...ctas[1], index: "lp-cta-1" })
        : null}
      <StyledTestimonials>
        <h2>What our customers say</h2>
        <SocialProofTestimonials />
      </StyledTestimonials>
      <ResponsiveSection>
        {features && features.length
          ? features.map((item, index) => (
              <LandingPageFeature key={`lp-features-${index}`}>
                <Image wrapper {...item.image} />
                <HtmlContent html={item.details} />
              </LandingPageFeature>
            ))
          : null}
      </ResponsiveSection>
      {ctas && ctas.length > 2
        ? renderCTA({ ...ctas[2], index: "lp-cta-2" })
        : null}
      {bestTimeBlocks && bestTimeBlocks.length ? (
        <ResponsiveSection>
          {bestTimeTitle ? <h2>{bestTimeTitle}</h2> : null}
          {bestTimeBlocks.map((item, index) => (
            <BestTimeBlock key={`lp-best-times-${index}`}>
              {item.image ? <Image wrapper {...item.image} /> : null}
              <HtmlContent html={item.details} />
            </BestTimeBlock>
          ))}
        </ResponsiveSection>
      ) : null}
      {footer ? (
        <ResponsiveSection html={`<h2>${footer.title}</h2>${footer.details}`} />
      ) : null}
      <SocialProofMediaPartners />
      {footerTiles && footerTiles.length ? (
        <ResponsiveSection>
          {footerTilesTitle ? <h2>{footerTilesTitle}</h2> : null}
          <HighlightTiles id="landing-page-footer-tiles" tiles={footerTiles} />
        </ResponsiveSection>
      ) : null}
    </PrimaryLayout>
  )
}
PageTemplate.propTypes = {
  data: PropTypes.shape({
    tenAdventures: PropTypes.shape({
      landingPage: PropTypes.shape({
        title: PropTypes.string,
        uri: PropTypes.string,
        content: PropTypes.string,
        headerImage: PropTypes.shape({ ...ImagePropTypes }),
        seo: PropTypes.shape({ ...PageSeoPropTypes }),
        headerHero: PropTypes.shape({
          title: PropTypes.string,
          text: PropTypes.string,
          theme: PropTypes.string,
          buttons: PropTypes.arrayOf(
            PropTypes.shape({
              uri: PropTypes.string,
              text: PropTypes.string,
            })
          ),
        }),
        ctas: PropTypes.arrayOf(
          PropTypes.shape({
            theme: PropTypes.string,
            text: PropTypes.string,
            buttons: PropTypes.arrayOf(
              PropTypes.shape({
                uri: PropTypes.string,
                text: PropTypes.string,
              })
            ),
          })
        ),
        targetPages: PropTypes.arrayOf(
          PropTypes.shape({
            pageType: PropTypes.string,
            pageTitle: PropTypes.string,
            uri: PropTypes.string,
            promoText: PropTypes.string,
            promoBg: PropTypes.shape({ ...ImagePropTypes }),
            formQuestion: PropTypes.string,
          })
        ),
        highlightTilesTitle: PropTypes.string,
        highlightTiles: PropTypes.arrayOf(
          PropTypes.shape({
            ...HighlightTilePropTypes,
          })
        ),
        detailsTitle: PropTypes.string,
        detailsImage: PropTypes.shape({ ...ImagePropTypes }),
        detailsAccordion: PropTypes.arrayOf(
          PropTypes.shape({
            icon: PropTypes.string,
            label: PropTypes.string,
            details: PropTypes.string,
          })
        ),
        detailsFooter: PropTypes.string,
        features: PropTypes.arrayOf(
          PropTypes.shape({
            details: PropTypes.string,
            image: PropTypes.shape({ ...ImagePropTypes }),
          })
        ),
        bestTimeTitle: PropTypes.string,
        bestTimeBlocks: PropTypes.arrayOf(
          PropTypes.shape({
            details: PropTypes.string,
            image: PropTypes.shape({ ...ImagePropTypes }),
          })
        ),
        footer: PropTypes.shape({
          title: PropTypes.string,
          details: PropTypes.string,
        }),
        footerTilesTitle: PropTypes.string,
        footerTiles: PropTypes.arrayOf(
          PropTypes.shape({
            ...HighlightTilePropTypes,
          })
        ),
      }),
    }),
  }),
}
export default PageTemplate
export const query = graphql`
  query ($id: ID!) {
    tenAdventures {
      landingPage(id: $id, idType: DATABASE_ID) {
        title
        uri
        content
        headerImage {
          ...TenAdventuresGQL_ImageSizesQuery
        }
        seo {
          ...TenAdventuresGQL_PostSEO
        }
        headerHero {
          title
          text
          theme
          buttons {
            uri
            text
          }
        }
        ctas {
          theme
          text
          buttons {
            uri
            text
          }
        }
        targetPages {
          pageType
          pageTitle
          uri
          promoText
          promoBg {
            ...TenAdventuresGQL_ImageSizesQuery
          }
          formQuestion
        }
        highlightTilesTitle
        highlightTiles {
          ...TenAdventuresGQL_HighlightTile
        }
        detailsTitle
        detailsImage {
          ...TenAdventuresGQL_ImageSizesQuery
        }
        detailsAccordion {
          icon
          label
          details
        }
        detailsFooter
        features {
          details
          image {
            ...TenAdventuresGQL_ImageSizesQuery
          }
        }
        bestTimeTitle
        bestTimeBlocks {
          details
          image {
            ...TenAdventuresGQL_ImageSizesQuery
          }
        }
        footer {
          title
          details
        }
        footerTilesTitle
        footerTiles {
          ...TenAdventuresGQL_HighlightTile
        }
      }
    }
  }
`
