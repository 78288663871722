import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import HighlightTile, {
  HighlightTilePropTypes,
} from "@src/components/content-highlight-tile"
import TourHighlightTile from "@src/components/content-highlight-tile-tour"

const StyledHighlightTiles = styled.div`
  & > div {
    margin-top: 15px;
  }

  @media only screen and (${props => props.theme.screen.small.min}) {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: stretch;
    margin: 0 -5px;
    & > div {
      margin: 0 5px 10px;
      flex: 0 0 auto;
      width: calc(50% - 10px);
    }
  }
  @media only screen and (${props => props.theme.screen.medium.min}) {
    & > div {
      width: calc(25% - 10px);
    }
  }
`

const HighlightTiles = ({ className, id, tiles }) => {
  return (
    <StyledHighlightTiles className={className} id={id}>
      {tiles && tiles.length
        ? tiles.map((tile, index) => {
            const itemKey = `${id}-${index}`
            if (tile.tileType === "TOURS") {
              return <TourHighlightTile key={itemKey} id={itemKey} {...tile} />
            }
            return <HighlightTile key={itemKey} id={itemKey} {...tile} />
          })
        : null}
    </StyledHighlightTiles>
  )
}
HighlightTiles.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
  tiles: PropTypes.arrayOf(
    PropTypes.shape({
      ...HighlightTilePropTypes,
    })
  ),
}
export default HighlightTiles
