import React, { useCallback } from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import styled from "styled-components"
import Image, { ImagePropTypes } from "@src/components/core-image"
import HtmlContent, { htmlToJsx } from "@src/components/core-value-html"
import Button from "@src/components/core-button"

const StyledHtmlContent = styled(HtmlContent)``
const StyledButton = styled(Button)`
  @media only screen and (${props => props.theme.screen.small.min}) {
    padding: 12px 20px;
  }
`
const StyledHighlightTile = styled.div`
  padding: 18px;
  border: 1px solid ${props => props.theme.border1};
  border-radius: 4px;
  & > h3 {
    font-size: 18px;
    line-height: 1.5em;
  }
  display: flex;
  flex-direction: column;
  & > * {
    flex: 0 0 auto;
  }
  & > ${StyledHtmlContent} {
    flex: 1 0 auto;
  }
`

const HighlightTile = ({
  id,
  className,
  renderDetails,
  title,
  image,
  content,
  details,
  button,
}) => {
  const generateDataLayer = useCallback(
    () => ({
      "highlight-tile": title,
    }),
    [title]
  )
  return (
    <StyledHighlightTile id={id} className={className}>
      <Image
        wrapper
        artDirection={{ mobile: "100vw", small: "275px" }}
        {...image}
      />
      <h3>{title}</h3>
      <StyledHtmlContent html={content} />
      {renderDetails ? (
        renderDetails(details)
      ) : (
        <ul className="primary">
          {details && details.length
            ? details.map(detail => (
                <li key={`${id}-details-${detail.key}`}>
                  {htmlToJsx(detail.value)}
                </li>
              ))
            : null}
        </ul>
      )}
      {button && button.uri && button.text ? (
        <StyledButton
          name={`${id}`}
          href={button.uri}
          color="yellow-black"
          size="full-width-large"
          variant="rectangle"
          generateDataLayer={generateDataLayer}
        >
          {button.text}
        </StyledButton>
      ) : null}
    </StyledHighlightTile>
  )
}
export const HighlightTilePropTypes = {
  tileType: PropTypes.oneOf(["BASIC", "TOURS"]),
  title: PropTypes.string,
  content: PropTypes.string,
  details: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    })
  ),
  button: PropTypes.shape({
    uri: PropTypes.string,
    text: PropTypes.string,
  }),
  image: PropTypes.shape({ ...ImagePropTypes }),
}
HighlightTile.propTypes = {
  ...HighlightTilePropTypes,
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  renderDetails: PropTypes.func,
}
export default HighlightTile

export const HighlightTileQueries = graphql`
  fragment TenAdventuresGQL_HighlightTile on TenAdventuresGQL_ContentTile {
    tileType
    title
    content
    details {
      key
      value
    }
    button {
      uri
      text
    }
    image {
      ...TenAdventuresGQL_ImageSizesQuery
    }
  }
`
