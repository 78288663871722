import { useStaticQuery, graphql } from "gatsby"

export const useTestimonials = () => {
  const {
    allWpTestimonial: { nodes: testimonials },
  } = useStaticQuery(graphql`
    query TestimonialsQuery {
      allWpTestimonial {
        nodes {
          id: databaseId
          content
          authorName
          authorImage {
            ...WpImageSizesQuery
          }
          sorting
        }
      }
    }
  `)
  testimonials.sort((a, b) => {
    if (a.sorting && (!b.sorting || a.sorting < b.sorting)) {
      return -1
    } else if (b.sorting && (!a.sorting || b.sorting < a.sorting)) {
      return 1
    }
    return 0
  })
  return testimonials
}
