import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import Image from "@src/components/core-image"
import ResponsiveSection from "@src/components/container-responsive-section"
import { REASONS_TO_BOOK } from "@src/utils/constants/social-proof-reasons-to-book"

const SocialProofContainer = styled(ResponsiveSection)`
  background-color: #daece4; // Refactor into theme with design guide update
  padding: 30px 20px;
  justify-items: center;

  // Font
  & > * {
    font-family: system-ui; // New scheme - Natasha
    letter-spacing: 0.5px; // New scheme - Natasha
    font-size: 1rem;
    line-height: 1.5rem;
    text-align: center;
  }

  & h2,
  & h3 {
    font-family: system-ui; // New scheme - Natasha
    letter-spacing: 0.5px; // New scheme - Natasha
  }

  & h2 {
    font-size: 1.75rem;
    font-weight: 900;
    // Required for underline
    line-height: 2.5rem;
    // Underline
    & > span.underline {
      display: inline-block;
      background-image: url(/images/social-proof-underline.svg);
      background-repeat: no-repeat;
      background-position-y: bottom;
      background-position-x: center;
      height: 50px;
    }
  }

  & h3 {
    font-weight: 700;
    font-size: 1.25rem;
    line-height: 2rem;
  }
  & > div.reasons-container {
    padding: 20px 0;
    & > div.reason {
      :not(:last-child) {
        margin-bottom: 40px;
      }
      // Image Sizes
      & > div.lazyload-wrapper {
        display: flex;
        justify-content: center;
        margin-bottom: 10px;
        & > img {
          height: 85px;
        }
      }
    }
  }

  @media only screen and (${props => props.theme.screen.small.min}) {
    & > div.reasons-container {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: center;
      gap: 20px;
      & > div.reason {
        display: flex;
        flex-direction: column;
        flex-basis: calc(50% - 40px);
        justify-content: center;
        // Remove 40px margin from vertical layout
        :not(:last-child) {
          margin-bottom: 0px;
        }
        // Required to maintain bottom alignment between images
        & > p {
          height: 72px;
        }
      }
    }
  }
  @media only screen and (${props => props.theme.screen.medium.min}) {
    & > div.reasons-container {
      flex-wrap: nowrap;
      & > div.reason {
        flex-basis: unset;
        // Required to maintain bottom alignment between images
        & > p {
          height: 90px;
        }
      }
    }
  }
`

const SocialProofReasons = () => {
  return (
    <SocialProofContainer>
      <h2>
        Why <span className="underline">book</span> with 10 Adventures?
      </h2>
      <div className="reasons-container">
        {REASONS_TO_BOOK.map(reason => (
          <div className="reason" key={reason.title}>
            <Image src={reason.image} alt={`${reason.title} Image`} />
            <h3>{reason.title}</h3>
            <p>{reason.details}</p>
          </div>
        ))}
      </div>
    </SocialProofContainer>
  )
}
SocialProofReasons.propTypes = {
  className: PropTypes.string,
}
export default SocialProofReasons
