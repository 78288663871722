import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import HighlightTile, {
  HighlightTilePropTypes,
} from "@src/components/content-highlight-tile"
import { htmlToJsx } from "@src/components/core-value-html"
import IconDetailContentTile from "@src/components/core-icon-detail-tile"
import CurrencyValue from "@src/components/core-value-local-currency"

const StyledDetailPriceAmount = styled(CurrencyValue)``
const StyledDetailPrice = styled.div`
  font-size: 1.1em;
  & .currency-code {
    font-size: 1em;
    margin-left: 0.25em;
  }
  & > ${StyledDetailPriceAmount} {
    font-weight: 500;
  }
  &.discounted-base {
    & > ${StyledDetailPriceAmount} {
      text-decoration: line-through;
    }
  }
  &.discounted-price {
    & > ${StyledDetailPriceAmount} {
      color: ${props => props.theme.red};
    }
  }
`
const StyledTileDetails = styled.div`
  margin-bottom: 20px;
  & > div {
    margin-bottom: 10px;
  }
`

const TourHighlightTile = ({ className, id, ...props }) => {
  const renderDetails = details => {
    const result = []

    if (details) {
      const priceText = details.find(check => check.key === "price_text")
      const tourPrice = details.find(check => check.key === "tour_price")
      const tourPriceData = details.find(
        check => check.key === "tour_price_data"
      )
      if (priceText) {
        result.push(
          <StyledDetailPrice key={`${id}-detail-price-text`}>
            {priceText.value}
          </StyledDetailPrice>
        )
      } else if (tourPrice) {
        result.push(
          <StyledDetailPrice key={`${id}-detail-tour-price`}>
            From {htmlToJsx(tourPrice.value, { prices: { showAbbr: "after" } })}
          </StyledDetailPrice>
        )
      } else if (tourPriceData) {
        const tourPrice = tourPriceData.value
        const hasDiscount =
          tourPrice?.discount && tourPrice?.amount && tourPrice?.amountBase
        result.push(
          <StyledDetailPrice
            key={`${id}-detail-tour-price-amount-base`}
            className={hasDiscount ? "discounted-base" : undefined}
          >
            From{" "}
            <StyledDetailPriceAmount
              amount={
                tourPrice?.amountBase
                  ? tourPrice?.amountBase
                  : tourPrice?.amount
              }
              code={tourPrice?.currency?.code}
              showAbbr="after"
            />
          </StyledDetailPrice>
        )
        if (hasDiscount) {
          result.push(
            <StyledDetailPrice
              key={`${id}-detail-tour-price-amount-discounted`}
              className="discounted-price"
            >
              Now from{" "}
              <StyledDetailPriceAmount
                amount={tourPrice?.amount}
                code={tourPrice?.currency?.code}
                showAbbr="after"
              />
            </StyledDetailPrice>
          )
        }
      }

      const durationDays = details.find(check => check.key === "duration_days")
      if (durationDays) {
        result.push(
          <IconDetailContentTile
            key={`${id}-detail-duration-days`}
            glyph="time2"
            details={`${durationDays.value} days`}
            color="yellow-black"
          />
        )
      }

      const tourType = details.find(check => check.key === "tour_type")
      if (tourType) {
        result.push(
          <IconDetailContentTile
            key={`${id}-detail-tour-type`}
            glyph="distance"
            details={tourType.value}
            color="yellow-black"
          />
        )
      }
    }

    return result.length ? (
      <StyledTileDetails>{result}</StyledTileDetails>
    ) : null
  }
  return (
    <HighlightTile
      id={id}
      className={className}
      renderDetails={renderDetails}
      {...props}
    />
  )
}
TourHighlightTile.propTypes = {
  ...HighlightTilePropTypes,
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
}
export default TourHighlightTile
