import React from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"
import Image, { ImagePropTypes } from "@src/components/core-image"
import ImageWrapper from "@src/components/core-image-wrapper"
import { htmlToJsx } from "@src/components/core-value-html"
import RatingStars from "@src/components/core-rating-stars"
const AuthorImageWrapper = styled(ImageWrapper)`
  border-radius: 50%;
  width: 55px;
  height: 55px;
`
const AuthorName = styled.p`
  margin: 0;
  font-weight: 500;
  font-size: 20px;
  @media only screen and (${props => props.theme.screen.small.min}) {
    font-size: 24px;
  }
`
const StyledTestimonial = styled.div`
  position: relative;
  padding: 35px 16px 16px;
  background-color: ${props => props.theme.bg1};
  ${props =>
    props.$variant === "indi-tour"
      ? css`
          display: flex;
          flex-direction: column;
          gap: 10px;
          align-items: center;
          justify-content: center;

          & > ${AuthorImageWrapper} {
            border: solid 2px ${props => props.theme.primary};
          }
          & > ${AuthorName} {
            color: ${props => props.theme.green2};
          }
          & > p {
            font-family: system-ui;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0.5px;
            text-align: center;
            color: "#1F1F23"; // Natasha design
          }
        `
      : css`
          display: grid;
          grid-template-rows: 1fr 55px;
          grid-template-columns: 55px 1fr;
          color: ${props => props.theme.darkGray};
          & > * {
            grid-row: 1 / 2;
            grid-column: 1 / 3;
          }
          & > p {
            font-size: 1rem;
          }
          & > ${AuthorName}, & > ${AuthorImageWrapper} {
            grid-row: 2 / 3;
          }
          & > ${AuthorName} {
            grid-column: ${props =>
              props.hasAuthorImage ? " 2 / 3" : "1 / 3"};
            color: ${props => props.theme.black};
            padding: ${props =>
              props.hasAuthorImage ? "16px 0 0 10px" : "16px 0 0 0"};
            font-size: 1.25rem;
          }
          & > ${AuthorImageWrapper} {
            grid-column: 1 / 2;
          }
          &::before {
            content: "“";
            position: absolute;
            left: 6px;
            color: ${props => props.theme.primary};
            font-size: 60px;
            font-weight: bold;
          }
          @media only screen and (${props => props.theme.screen.small.min}) {
            padding: 35px 30px 30px;
            &::before {
              left: 16px;
            }
            & > ${AuthorName} {
              padding: calc(27.5px - 0.5em) 0 0 10px;
              font-size: 1.5rem;
            }
          }
        `}
`
const SocialProofTestimonial = ({
  className,
  content,
  author,
  variant,
  ...props
}) => {
  const hasAuthorImage = !!author?.image
  return variant === "indi-tour" ? (
    <StyledTestimonial
      $variant={variant}
      className={`${className} testimonial`}
      {...props}
    >
      {author?.image && (
        <Image wrapper={AuthorImageWrapper} {...author?.image} />
      )}
      <RatingStars value={5} variant="black" />
      {htmlToJsx(content)}
      <AuthorName>{author?.name}</AuthorName>
    </StyledTestimonial>
  ) : (
    <StyledTestimonial
      className={`${className} testimonial`}
      hasAuthorImage={hasAuthorImage}
      {...props}
    >
      <AuthorName>{author?.name}</AuthorName>
      {author?.image && (
        <Image wrapper={AuthorImageWrapper} {...author?.image} />
      )}
      {htmlToJsx(content)}
    </StyledTestimonial>
  )
}
SocialProofTestimonial.propTypes = {
  className: PropTypes.string,
  content: PropTypes.string,
  author: PropTypes.shape({
    name: PropTypes.string,
    image: PropTypes.shape({ ...ImagePropTypes }),
  }),
  variant: PropTypes.oneOf(["indi-tour"]),
}
export default SocialProofTestimonial
