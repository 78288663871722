import React from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"
import { useTestimonials } from "@src/hooks/useTestimonials"
import Testimonial from "@src/components/content-social-proof-testimonial"

const StyledTestimonial = styled(Testimonial)``
const StyledTestimonials = styled.div`
  & > ${StyledTestimonial} {
    border-radius: 5px;
    box-shadow: 0 20px 40px -10px rgba(0, 0, 0, 0.1);
    margin-bottom: 10px;
  }
  @media only screen and (${props => props.theme.screen.small.min}) {
    display: flex;
    flex-wrap: wrap;
    & > ${StyledTestimonial} {
      margin: 5px;
      flex: 1 0 calc(50% - 10px);
    }
  }
  ${props =>
    props.variant !== "inline"
      ? css`
          @media only screen and (${props => props.theme.screen.desktop.min}) {
            & > ${StyledTestimonial} {
              flex: 1 0 calc(25% - 10px);
            }
          }
        `
      : ""}
`
const SocialProofTestimonials = ({ variant }) => {
  const testimonials = useTestimonials()
  return (
    <StyledTestimonials variant={variant}>
      {testimonials.slice(0, 4).map(testimonial => (
        <StyledTestimonial
          key={`testimonial-${testimonial.id}`}
          content={testimonial.content}
          author={{
            name: testimonial.authorName,
            image: testimonial.authorImage,
          }}
        />
      ))}
    </StyledTestimonials>
  )
}
SocialProofTestimonials.propTypes = {
  variant: PropTypes.oneOf(["default", "inline"]),
}
export default SocialProofTestimonials
